/* Default Theme */
/* Link Color */
a:hover {
  color: #FFCC00;
}

/* Button */
.btn-devent {
  background-color: #FFCC00;
  border-color: #FFCC00;
}

.btn-devent:hover, .btn-devent:focus {
  background-color: #b38f00;
}

.btn-devent.btn-hs:hover, .btn-devent.btn-hs:focus {
  color: #FFCC00;
}

.btn-devent.dt-alt {
  background-color: #106EE8;
  border-color: #106EE8;
}

.btn-devent.dt-alt:hover, .btn-devent.dt-alt:focus {
  background-color: #0b4ca0;
}

.btn-devent.dt-alt.btn-hs:hover, .btn-devent.dt-alt.btn-hs:focus {
  color: #106EE8;
}

.btn-devent.btn-alt-2 {
  background-color: #69a6f5;
  border-color: #69a6f5;
}

.btn-devent.btn-alt-2:hover, .btn-devent.btn-alt-2:focus {
  background-color: #3a8af1;
}

.btn-devent.btn-alt-2.btn-hs:hover, .btn-devent.btn-alt-2.btn-hs:focus {
  color: #69a6f5;
}

.dt-play-btn {
  background-color: rgba(255, 204, 0, 0.8);
}

.dt-play-btn:before {
  background: rgba(255, 204, 0, 0.6);
}

.dt-play-btn:after {
  background: rgba(255, 204, 0, 0.4);
}

/* Forms */
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="time"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="datetime-local"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
textarea:focus,
select:focus,
.form-control:focus {
  border-color: #106EE8;
}

.transparent-form input[type="text"]:focus, .transparent-form
input[type="password"]:focus, .transparent-form
input[type="email"]:focus, .transparent-form
input[type="number"]:focus, .transparent-form
input[type="time"]:focus, .transparent-form
input[type="date"]:focus, .transparent-form
input[type="month"]:focus, .transparent-form
input[type="week"]:focus, .transparent-form
input[type="datetime-local"]:focus, .transparent-form
input[type="search"]:focus, .transparent-form
input[type="tel"]:focus, .transparent-form
input[type="url"]:focus, .transparent-form
textarea:focus, .transparent-form
select:focus, .transparent-form
.form-control:focus {
  border-color: #106EE8;
}

/* Site Header */
.header-top-area {
  background-color: #106EE8;
}

.header-top-bar {
  border-top-color: #FFCC00;
}

.header-top-bar ul > li > a:hover {
  color: #FFCC00;
}

.transparent-header:not(.fix-header) .header-top-bar ul > li > a:hover {
  color: #106EE8;
}

/* Site Navbar */
.dt-standard-navbar .nav.navbar-nav > li > a:hover, .dt-standard-navbar .nav.navbar-nav > li > a:focus {
  color: #106EE8;
}

.dt-standard-navbar .nav.navbar-nav > li.active > a,
.dt-standard-navbar .nav.navbar-nav > li.current-menu-item > a,
.dt-standard-navbar .nav.navbar-nav > li.current-menu-ancestor > a {
  color: #106EE8;
}

@media (max-width: 767px) {
  .dt-standard-navbar .navbar-header {
    background-color: #106EE8;
    border-top: 1px solid #0e63d0;
  }
  .dt-standard-navbar .navbar-header > .navbar-toggle > .icon-bar {
    background-color: white;
  }
}

.transparent-header .dt-standard-navbar .nav.navbar-nav > li > a:hover, .transparent-header .dt-standard-navbar .nav.navbar-nav > li > a:focus {
  color: #106EE8;
}

.transparent-header .dt-standard-navbar .nav.navbar-nav > li.active > a,
.transparent-header .dt-standard-navbar .nav.navbar-nav > li.current-menu-item > a,
.transparent-header .dt-standard-navbar .nav.navbar-nav > li.current-menu-ancestor > a {
  color: #106EE8;
}

.transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li > a:hover, .transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li > a:focus {
  color: #106EE8;
}

.transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li.active > a,
.transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li.current-menu-item > a,
.transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li.current-menu-ancestor > a {
  color: #106EE8;
}

/* Banner */
.site-banner > .banner-statics .event-statics {
  background-color: rgba(16, 110, 232, 0.3);
}

/* Slider Control */
.carousel-button-prev,
.carousel-button-next {
  border-color: #FFCC00;
}

.carousel-button-prev > i,
.carousel-button-next > i {
  color: #FFCC00;
}

.carousel-button-prev:hover,
.carousel-button-next:hover {
  background-color: #106EE8;
  border-color: #106EE8;
}

.transparent-area .carousel-button-prev:hover > i, .transparent-area
.carousel-button-next:hover > i {
  color: #106EE8;
}

/* Section Heading */
.section-heading > .title > .effect:before {
  background-color: #FFCC00;
}

.section-heading > .title > .effect:after {
  background-color: #106EE8;
}

/* Lists */
ul {
  /* Styled List */
  /* Social profiles */
  /* Post Meta */
}

ul.styled-list > li:before {
  border-color: #FFCC00;
}

ul.social-profiles > li > a:hover {
  background-color: #106EE8;
  border-color: #106EE8;
}

ul.social-profiles.transparent > li > a:hover,
.transparent-area ul.social-profiles > li > a:hover {
  color: #106EE8;
}

ul.social-profiles-v2 > li > a:hover {
  color: #FFCC00;
}

ul.social-profiles-v2.transparent > li > a:hover,
.transparent-area ul.social-profiles-v2 > li > a:hover {
  color: #FFCC00;
}

ul.post-meta > li > i {
  color: #FFCC00;
}

ul.post-meta > li > .dt-sprite {
  fill: #FFCC00;
}

/* About The Event */
.about-the-event > .nav > li > a:hover {
  background-color: #3a8af1;
  border-color: #3a8af1;
}

.about-the-event > .nav > li.active > a {
  background-color: #106EE8;
  border-color: #106EE8;
}

/* Event Countdown */
.event-countdown.style-two > .countdown:after, .event-countdown.style-two > .countdown:before {
  background-color: rgba(255, 204, 0, 0.7);
}

@media (max-width: 1440px) {
  .event-countdown.style-two > .event-info {
    background-color: rgba(255, 204, 0, 0.7);
  }
}

/* Event Schedule Tabs */
.event-schedule-tabs > .nav > li > a:hover {
  background-color: #106EE8;
  border-color: #106EE8;
}

.event-schedule-tabs > .nav > li.active > a {
  background-color: #106EE8;
  border-color: #106EE8;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a {
  background-color: #106EE8;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a > .time {
  background-color: #0e5ec7;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a.collapsed:after {
  color: #FFCC00;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a.collapsed > .time > i {
  color: #FFCC00;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a:hover {
  background-color: #106EE8;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a:hover > .time {
  background-color: #0e5ec7;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body {
  background-color: #106EE8;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .time:before {
  color: #FFCC00;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .schedule-speaker > .speaker-info > i {
  color: #FFCC00;
}

/* Author Bio */
.post-author-bio > .author-image {
  border-color: #106EE8;
}

/* Pricing Table */
.pricing-item > .plan-price {
  background-color: #FFCC00;
}

.pricing-item:hover > .plan-price, .pricing-item.featured > .plan-price {
  background-color: #106EE8;
}

/* Recent News */
.recent-news-list article.post > .post-header > .post-title > a:hover {
  color: #106EE8;
}

/* Site Footer */
.copyright-info-area .copyright-notice > a:hover {
  color: #FFCC00;
}

.copyright-info-area.transparent-area .copyright-notice > a:hover {
  color: #FFCC00;
}

/* Recent Entries Widget */
.widget_recent_entries ul li:before,
.widget_meta ul li:before,
.widget_categories ul li:before,
.widget_archive ul li:before,
.widget_recent_comments ul li:before,
.widget_pages ul li:before,
.widget_nav_menu ul li:before {
  color: #FFCC00;
}

/* About Us Widget */
.widget_dt_about_us > .contact-method > li > .dt-sprite {
  fill: #FFCC00;
}

.widget_dt_about_us > .contact-method > li > i {
  color: #FFCC00;
}

/* Tag Cloud */
.widget_tag_cloud > .tagcloud > a {
  border-color: #FFCC00;
  color: #FFCC00;
}

.widget_tag_cloud > .tagcloud > a:hover {
  background-color: #FFCC00;
}

/* Page Header */
.site-page-header {
  text-align: center;
}

.site-page-header .breadcrumbs > li {
  color: #106EE8;
}

/*  Speaker Profile (v2) */
.speaker-profile-thumb-v2 > .profile-image > .overlay > a:hover {
  color: #FFCC00;
}

.speaker-profile-thumb-v2 > .profile-info > .name > a:hover {
  color: #106EE8;
}

/* Navigation */
.navigation.pagination > .nav-links > a.current, .navigation.pagination > .nav-links > a:hover,
.navigation.pagination > .nav-links > span:not(.dots).current,
.navigation.pagination > .nav-links > span:not(.dots):hover {
  background-color: #106EE8;
  border-color: #106EE8;
}

.navigation.post-navigation > .nav-links > div > a:hover {
  color: #FFCC00;
}

/* Page Links */
.page-links > a,
.page-links > .page-number {
  display: inline-block;
  border: 1px solid #eeeeee;
  min-width: 30px;
  min-height: 30px;
  text-align: center;
  color: #050709;
  line-height: 2;
  padding: 0 7px;
  margin-bottom: 5px;
}

.page-links a:hover,
.page-links > .page-number {
  color: #FFF;
  background-color: #106EE8;
  border-color: #106EE8;
}

.page-links > .title {
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #eeeeee;
  line-height: 2;
  padding: 0 7px;
  margin-bottom: 5px;
}

/* Contact Method Box */
.contact-method-box {
  background-color: #106EE8;
}

.contact-method-box > .inside {
  border-color: #99c2f8;
}

.contact-method-box > .inside:before {
  background-color: #99c2f8;
}

/* Error 404 */
.error-404-content > .error-code {
  color: #106EE8;
}

/* Article */
.hentry .entry-meta > li > i {
  color: #FFCC00;
}

.hentry .entry-meta > li.sharer > .sharer-items {
  background-color: #FFCC00;
}

.hentry .entry-meta > li.sharer > .sharer-items > a:hover {
  color: #FFCC00;
}

.hentry > .post-content > .entry-title > a:hover {
  color: #106EE8;
}

/* Comments */
#comments > .comment-list li.comment > .comment-body > .reply a {
  border-color: #FFCC00;
  background-color: #FFCC00;
}

#comments > .comment-list li.comment > .comment-body > .reply a:hover {
  color: #FFCC00;
}

#comments > .comment-list li.bypostauthor > .comment-body {
  border-color: #FFCC00;
}

#comments > .comment-list li.bypostauthor > .comment-body > .comment-meta > .comment-author > img {
  border-color: #FFCC00;
}

#respond #cancel-comment-reply-link {
  color: #FFCC00;
}

/* WooCommerce */
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
  background-color: #FFCC00;
  border-color: #FFCC00;
}

.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover {
  color: #FFCC00;
}

.woocommerce a.added_to_cart {
  background-color: #106EE8;
  border-color: #106EE8;
}

.woocommerce a.added_to_cart:hover {
  color: #106EE8;
}

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
  background-color: #FFCC00;
}

.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover {
  background-color: #806600;
}

.woocommerce #respond input#submit.alt.disabled,
.woocommerce #respond input#submit.alt.disabled:hover,
.woocommerce #respond input#submit.alt:disabled,
.woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce #respond input#submit.alt:disabled[disabled],
.woocommerce #respond input#submit.alt:disabled[disabled]:hover,
.woocommerce a.button.alt.disabled,
.woocommerce a.button.alt.disabled:hover,
.woocommerce a.button.alt:disabled,
.woocommerce a.button.alt:disabled:hover,
.woocommerce a.button.alt:disabled[disabled],
.woocommerce a.button.alt:disabled[disabled]:hover,
.woocommerce button.button.alt.disabled,
.woocommerce button.button.alt.disabled:hover,
.woocommerce button.button.alt:disabled,
.woocommerce button.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled[disabled],
.woocommerce button.button.alt:disabled[disabled]:hover,
.woocommerce input.button.alt.disabled,
.woocommerce input.button.alt.disabled:hover,
.woocommerce input.button.alt:disabled,
.woocommerce input.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled[disabled],
.woocommerce input.button.alt:disabled[disabled]:hover {
  background-color: #FFCC00;
}

.woocommerce #respond input#submit.disabled:hover,
.woocommerce #respond input#submit:disabled:hover,
.woocommerce #respond input#submit:disabled[disabled]:hover,
.woocommerce a.button.disabled:hover,
.woocommerce a.button:disabled:hover,
.woocommerce a.button:disabled[disabled]:hover,
.woocommerce button.button.disabled:hover,
.woocommerce button.button:disabled:hover,
.woocommerce button.button:disabled[disabled]:hover,
.woocommerce input.button.disabled:hover,
.woocommerce input.button:disabled:hover,
.woocommerce input.button:disabled[disabled]:hover {
  color: #FFCC00;
}

.woocommerce #content table.cart td.actions .input-text:focus,
.woocommerce table.cart td.actions .input-text:focus,
.woocommerce-page #content table.cart td.actions .input-text:focus,
.woocommerce-page table.cart td.actions .input-text:focus {
  border-color: #106EE8;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle,
.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
  background-color: #FFCC00;
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: #806600;
}

.woocommerce nav.woocommerce-pagination > ul > li {
  border-color: #106EE8;
}

.woocommerce nav.woocommerce-pagination > ul > li > span.current,
.woocommerce nav.woocommerce-pagination > ul > li > a:hover {
  background-color: #106EE8;
}
